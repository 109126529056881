import React, { Component } from 'react';
import { AntCard, TagInverterStatus, ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import * as Icon from "@ant-design/icons";
import { Table } from "antd";

class CustomComponent extends Component {
    render() {
        const { common, refreshPlantData, showReload } = this.props;

        const parentElementId = "plant-overview-card";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''
        const { plantOverview } = this.props.plant;
        const { data, loading, type, dataDetail } = plantOverview;
        let tableData = type === 'overview' ? data : dataDetail;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <Icon.FundViewOutlined /> Plant Overview
                        </div>
                        <span className="toolbox-control select-time-control">
                            <ToolboxControl
                                // updateAt={lastTime}
                                // disabled={!inverterList.length}
                                showReload={showReload}
                                handleReload={refreshPlantData}
                                parentElementId={parentElementId}
                            // selectInverterOptions={inverterList}
                            // handleChangeInverter={handleChangeInverter}
                            // defaultDeviceCode={defaultDeviceCode}
                            />
                        </span>
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height">
                    <Table
                        columns={columns(type)}
                        dataSource={tableData}
                        pagination={false}
                        loading={loading}
                        rowKey={"name"}
                        scroll={{ x: 1000, y: 400 }}
                    />
                </div>
            </AntCard>
        )
    }
}

const columns = type => {
    const columnsLabel = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            ellipsis: true,
            align: 'center',
            render: value => <TagInverterStatus level={value} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ellipsis: true,
            align: 'left',
            // render: (text, item) =>
            //     <div>
            //         <InsertRowBelowOutlined/>
            //         <span
            //             className="highlight"
            //             data-domain-id={"1"}
            //             data-site-id={item.siteId}
            //             data-plant-id={item.id}
            //             onClick={() => console.log("click")}> {text}</span>
            //         <Link to={`/technical-monitoring/plant/${item.id}`}> <ImportOutlined className="transform-180"/></Link>
            //     </div>,
        },
        {
            title: 'Current Power (kW)',
            dataIndex: 'plantRealTimePower',
            key: 'plantRealTimePower',
            width: '20%',
            ellipsis: true,
            align: 'center',
        },
        {
            title: 'Production Today (kWh)',
            dataIndex: 'plantProductionToday',
            key: 'plantProductionToday',
            width: '20%',
            ellipsis: true,
            align: 'center',
        },
        {
            title: 'Specific Yield Today (kWh/kWp)',
            dataIndex: 'plantSpecificYieldToday',
            key: 'plantSpecificYieldToday',
            width: '20%',
            ellipsis: true,
            align: 'center',
        },
        {
            title: 'Performance Ratio Today (%)',
            dataIndex: 'plantPerformanceRatioToday',
            key: 'plantPerformanceRatioToday',
            width: '20%',
            ellipsis: true,
            align: 'center',
        },
    ]
    if (type === 'overview') {
        for (let i = 0; i < columnsLabel.length; ++i) {
            if (columnsLabel[i].dataIndex !== 'name' && columnsLabel[i].dataIndex !== 'status') {
                columnsLabel[i] = {
                    ...columnsLabel[i],
                    sorter: (a, b) => a[columnsLabel[i].dataIndex] - b[columnsLabel[i].dataIndex],
                };
            }
        }
    }
    return columnsLabel;
}

const mapStateToProps = state => {
    return {
        common: state.common,
        plant: state.plant,
    }
}
export default connect(mapStateToProps, {})(CustomComponent);
import React from 'react';
import {
    ReactFlow,
    useNodesState,
    useEdgesState,
} from '@xyflow/react';

import { AnimatedSVGEdge } from './AnimatedSVGEdge';

import battery from "@images/flow/battery.png"
import factory from "@images/flow/factory.png"
import grid from "@images/flow/grid.png"
import solar from "@images/flow/solar.png"
import { ArrowDownOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Fade } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const edgeTypes = {
    animatedSvg: AnimatedSVGEdge,
};

const initialEdges = [
    { id: '1->2', type: 'animatedSvg', source: '1', target: '2' },
    { id: '2->3', type: 'animatedSvg', source: '2', target: '3' },
    { id: '3->4', type: 'animatedSvg', source: '3', target: '4' },
    { id: '11->33', type: 'animatedSvg', source: '11', target: '33' },
    { id: '22->44', type: 'animatedSvg', source: '22', target: '44' },
    { id: '111->444', type: 'animatedSvg', source: '111', target: '444' },
];

const EdgesFlow = props => {
    const { flowDrag, toggleFlow } = props

    const useViewport = () => {

        const saveView = () => {
            if (flowDrag) {
                toggleFlow()
            }
            localStorage.setItem('Z-view', JSON.stringify(state))
        }

        const loadLocalView = () => {
            return JSON.parse(localStorage.getItem('Z-view'))
        }

        const defaultView = loadLocalView() ?? { x: 380, y: 105, zoom: 0.5 }

        const [state, setState] = React.useState(defaultView)

        const setView = React.useCallback(({ x, y, zoom }) => {
            setState(prevState => ({
                ...prevState,
                x: x ?? prevState.x,
                y: y ?? prevState.y,
                zoom: zoom ?? prevState.zoom
            }));
        }, []);

        return { defaultView, currentView: state, setView, saveView }
    }

    const slideDownKeyframes = keyframes`
        0% { opacity: 0; transform: translateY(-50%); }
        50% { opacity: 1; transform: translateY(0); }
        100% { opacity: 0; transform: translateY(50%); }
    `;

    const slideRightKeyframes = keyframes`
        0% { transform: translateX(-20px); opacity: 0; }
        50% { transform: translateX(0); opacity: 1; }
        100% { transform: translateX(20px); opacity: 0; }
    `;

    const initialNodes = [
        // GROUP 1 standard
        {
            id: '1',
            position: { x: 100, y: -100 },
            data: {
                label: <>
                    <img className={'hidden-group'} src={solar} alt="solar" />
                </>
            },
            sourcePosition: 'left',
            targetPosition: 'right'
        },
        {
            id: '2',
            position: { x: -300, y: 120 },
            data: {
                label: <>
                    <img className={'hidden-group'} src={grid} alt="grid" />
                </>
            }
        },
        {
            id: '3',
            position: { x: 100, y: 300 },
            data: {
                label: <>
                    <img className={'hidden-group'} src={battery} alt="grid" style={{ height: '300px' }} />
                </>
            },
            sourcePosition: 'right',
            targetPosition: 'left'
        },
        {
            id: '4',
            position: { x: 500, y: 120 },
            data: {
                label: <>
                    <img className={'hidden-group'} src={factory} alt="grid" style={{ height: '200px' }} />
                </>
            },
            targetPosition: 'bottom',
            sourcePosition: 'bottom'
        },
        // GROUP 2 overwrite
        // Item 1: from solar to battery
        {
            id: '11',
            position: { x: 100, y: -100 },
            data: {
                label: <>
                    <img className={'hidden-group'} src={solar} alt="solar" />
                </>
            },
            sourcePosition: 'bottom',
            targetPosition: 'bottom'
        },
        {
            id: '33',
            position: { x: 100, y: 300 },
            data: {
                label: <>
                    <div>
                        <img
                            src={battery}
                            alt="grid"
                            style={{ height: '300px', marginLeft: '15px', marginTop: '180px' }}
                        />
                        <p style={{ fontSize: '30px', paddingBottom: '20px', margin: 0 }}>
                            <span style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '20px' }}>
                                <Fade
                                    keyframes={slideDownKeyframes}
                                    duration={1000}  // 1 second per iteration
                                    triggerOnce={false}  // Repeats the animation
                                    style={{ animationIterationCount: "infinite" }}
                                >
                                    <ArrowDownOutlined style={{ color: '#ffa940', fontWeight: 'bold', fontSize: '40px' }} />
                                </Fade>
                                <span style={{ marginLeft: '-8px' }}>95%</span>
                            </span>
                            <br />
                            20kW
                        </p>
                    </div>
                </>
            },
        },
        // Item 2: from grid to factory 
        {
            id: '22',
            position: { x: -300, y: 120 },
            data: {
                label: <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row', // Sắp xếp p và img theo hàng ngang
                            alignItems: 'center', // Căn giữa theo trục dọc
                            gap: '1px', // Khoảng cách giữa p và img
                        }}
                    >
                        {/* Phần thẻ P */}
                        <p
                            style={{
                                position: 'absolute',
                                top: '50%',       // Điều chỉnh vị trí theo ý muốn
                                left: '50%',      // Điều chỉnh vị trí theo ý muốn
                                transform: 'translate(-270%, -50%)',
                                fontSize: '30px',
                                margin: 0,
                                zIndex: 1         // Đảm bảo <p> nằm trên <img>
                            }}
                        >
                            {/* {chargeData.gridPower} */}
                            328W <br />
                            <Fade
                                keyframes={slideRightKeyframes}
                                duration={1500}  // 1 second per iteration
                                triggerOnce={false}  // Repeats the animation
                                style={{ animationIterationCount: "infinite" }}
                            >
                                <ArrowRightOutlined style={{ color: '#ffa940', fontWeight: 'bold', fontSize: '40px' }} />
                            </Fade>
                        </p>

                        {/* Phần hình ảnh */}
                        <img
                            src={grid}
                            alt="grid"
                            style={{ display: 'block', marginRight: '120px' }}
                        />
                    </div>
                </>
            },
            targetPosition: 'right',
            sourcePosition: 'right'
        },
        {
            id: '44',
            position: { x: 500, y: 120 },
            data: {
                label: <>
                    <img className={'hidden-group'} src={factory} alt="grid" style={{ height: '200px' }} />
                </>
            },
            targetPosition: 'left',
            sourcePosition: 'left'
        },
        // Item 3: from solar to factory
        {
            id: '111',
            position: { x: 100, y: -100 },
            data: {
                label: <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between', // Tách phần p và img
                            alignItems: 'center',
                            gap: '1px', // Khoảng cách nhỏ giữa p và img
                        }}
                    >
                        {/* Phần 1: Thẻ P */}
                        <div>
                            <p style={{ fontSize: '30px', margin: 0, textAlign: 'center' }}>
                                {/* {chargeData.solarPower} */}
                                19kW
                            </p>
                        </div>

                        {/* Phần 2: Hình ảnh */}
                        <div>
                            <img
                                src={solar}
                                alt="solar"
                                style={{ height: '250px', marginBottom: '45px', display: 'block' }}
                            />
                        </div>
                    </div>
                </>
            },
            sourcePosition: 'right',
        },
        {
            id: '444',
            position: { x: 500, y: 120 },
            data: {
                label: <>
                    {/*<img src={factory} alt="grid" style={{ height: '200px' }}/>*/}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row', // Sắp xếp theo hàng ngang, p bên phải của ảnh
                            alignItems: 'center', // Căn giữa theo trục dọc
                            gap: '1px', // Khoảng cách giữa p và img
                        }}
                    >
                        {/* Phần hình ảnh */}
                        <img
                            src={factory}
                            alt="factory"
                            style={{ height: '200px', display: 'block', marginLeft: '42px' }}
                        />

                        {/* Phần thẻ P */}
                        <p style={{ fontSize: '30px', margin: '0' }}>
                            {/* {chargeData.housePower} */}
                            2.32kW
                        </p>
                    </div>
                </>
            },
        }
    ]
    const [nodes, , onNodesChange] = useNodesState(initialNodes)
    const [edges, , onEdgesChange] = useEdgesState(initialEdges)

    const { defaultView, setView, saveView } = useViewport()

    return (
        <ReactFlow
            className={flowDrag ? 'toggleDraw' : null}
            nodes={nodes}
            edges={edges}
            edgeTypes={edgeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodesDraggable={false}
            zoomOnDoubleClick={flowDrag}
            zoomOnPinch={flowDrag}
            zoomOnScroll={flowDrag}
            draggable={flowDrag}
            panOnDrag={flowDrag}
            defaultViewport={defaultView}
            minZoom={0.2}
            maxZoom={10}
            onViewportChange={event => setView(event)}
            onMouseLeave={saveView}
        />
    );
};

export default EdgesFlow;
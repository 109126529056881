import React, { Component } from 'react';
import { ToolboxControl, AntCard, Loading } from "@layouts";
import { connect } from "react-redux";
import { DashboardOutlined, BarChartOutlined } from "@ant-design/icons";
import { Col, Divider, Row } from "antd";

class CustomComponent extends Component {
    render() {
        const { common, overview } = this.props
        const parentElementId = "site-gauges";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''
        // const { loading } = overview;

        return (
            <AntCard
                id={parentElementId}
                className={"card-style " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <DashboardOutlined /> Gauges
                        </div>
                        <ToolboxControl parentElementId={parentElementId} />
                    </div>
                }
            >
                <div className="card-custom-body">
                    {/* {loading ? <Loading overwrite /> : null} */}
                    <Row gutter={[16, 24]}>
                        {/* Charging Column */}
                        <Col xs={24} md={12}>
                            <div className="gauge-column">
                                <div className="column-header">Charging</div>
                                <div className="group-gauges group-output">
                                    <div className="group-title-mt">
                                        <DashboardOutlined /> Real-time
                                    </div>
                                    <Row gutter={16} className="group-value">
                                        <Col span={24}>
                                            <div className="wrap-value">
                                                <div className="value">
                                                    <div>20.51</div>
                                                    <div>69.96</div>
                                                </div>
                                                <div className="unit">
                                                    <div>kW</div>
                                                    <div>%</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        {/* Discharging Column */}
                        <Col xs={24} md={12}>
                            <div className="gauge-column">
                                <div className="column-header">Discharging</div>
                                <div className="group-gauges group-output">
                                    <div className="group-title-mt">
                                        <DashboardOutlined /> Real-time
                                    </div>
                                    <Row gutter={16} className="group-value">
                                        <Col span={24}>
                                            <div className="wrap-value">
                                                <div className="value">
                                                    <div>15.51</div>
                                                    <div>100.2</div>
                                                </div>
                                                <div className="unit">
                                                    <div>kW</div>
                                                    <div>Ah</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Divider className="divider" />
                    {/* Cumulative Production Section */}
                    <div className="group-gauges group-yield">
                        <div className="group-title-mt">
                            <BarChartOutlined /> Cumulative Production
                        </div>
                        <Row gutter={[32, 16]}>
                            <Col xs={24} md={12}>
                                <div className="production-section">
                                    <div className="value-main">
                                        198.9 
                                        <span className="unit">
                                            kWh
                                        </span>
                                    </div>
                                    <div className="production-stats">
                                        <div className="stat-item">
                                            <div className="label">Current Month</div>
                                            <div className="value">
                                                56.388
                                                <span>MWh</span>
                                            </div>
                                        </div>
                                        <div className="stat-item">
                                            <div className="label">Total</div>
                                            <div className="value">
                                                2.5220
                                                <span>GWh</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={24} md={12}>
                                <div className="production-section">
                                    <div className="value-main">
                                        383.3
                                        <span className="unit">
                                            kWh
                                        </span>
                                    </div>
                                    <div className="production-stats">
                                        <div className="stat-item">
                                            <div className="label">Current Month</div>
                                            <div className="value">
                                                56.388
                                                <span>MWh</span>
                                            </div>
                                        </div>
                                        <div className="stat-item">
                                            <div className="label">Total</div>
                                            <div className="value">
                                                56.388
                                                <span>GWh</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </AntCard>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common,
})

export default connect(mapStateToProps, {})(CustomComponent)
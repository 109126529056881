import React, { Component } from 'react';
import { AntCard, ToolboxControl, Loading } from "@layouts";
import { connect } from "react-redux";
import * as Icon from "@ant-design/icons";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class CustomComponent extends Component {
    render() {
        const { common, refreshPlantData, overview } = this.props;

        const parentElementId = "plant-overview-card";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';
        // const { loading, chargeData } = overview

        // Generate mockup data for the current date (24 hours)
        const hours = Array.from({ length: 24 }, (_, i) => `${i}:00`);

        const data = [{
            "name": "Charging",
            "data": [
                160,
                182,
                231,
                279,
                322,
                364,
                398,
                384,
                355,
                292,
                220,
                178,
                159,
                135,
                145,
                150,
                251,
                321,
                300,
                310,
                333,
                323,
                343,
                350,
                363
            ]
        },
        {
            "name": "Discharging",
            "data": [
                35,
                45,
                60,
                48,
                102,
                145,
                176,
                165,
                120,
                65,
                20,
                90,
                110,
                130,
                140,
                150,
                160,
                175,
                155,
                180,
                200,
                210,
                225,
                260,
                210
            ]
        }
        ]
        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <Icon.DotChartOutlined /> Charge Discharge Curve
                        </div>
                        <span className="toolbox-control select-time-control">
                            <ToolboxControl
                                handleReload={refreshPlantData}
                                parentElementId={parentElementId}
                            />
                        </span>
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height">
                    {/* {loading ? <Loading overwrite/> : null} */}
                    <HighchartsReact
                        containerProps={{ className: 'highcharts-react' }}
                        highcharts={Highcharts}
                        options={{
                            chart: {
                                type: 'area'
                            },
                            title: {
                                text: null
                            },
                            xAxis: {
                                categories: hours // Use hours for the X-axis
                            },
                            yAxis: {
                                title: {
                                    text: 'Power (kW)' // Set Y-axis title with units
                                }
                            },
                            plotOptions: {
                                line: {
                                    dataLabels: {
                                        enabled: true
                                    },
                                    enableMouseTracking: false
                                }
                            },
                            // series: chargeData?.series ?? []
                            series: data
                        }}
                    />
                </div>
            </AntCard>
        )
    }
}

const mapStateToProps = state => {
    return {
        common: state.common,
        plant: state.plant,
    }
}
export default connect(mapStateToProps, {})(CustomComponent);
import React, { Component } from 'react';
import { connect } from "react-redux";
import { AntCard, ToolboxControl, Loading } from "@layouts";
import { UnorderedListOutlined } from "@ant-design/icons";
import { findLocation } from "../../redux";
import { fetchTableEvent } from "@features/Event/redux";
import { getPlantDataOverview } from "@features/Plant/redux";
import { FlowComponent } from '../Flow';

class CustomComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            flowDrag: false
        }
    }

    toggleFlow = () => {
        this.setState(prevState => ({ flowDrag: !prevState.flowDrag }))
    };

    render = () => {
        const {
            common,
            overview,
        } = this.props

        const parentElementId = "site-plant-list";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

        // const {
        //     total,
        //     powerValue,
        //     powerUnit,
        // } = overview

        // const { chargeData } = overview

        return (
            <AntCard
                id={parentElementId}
                className={"card-style " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <UnorderedListOutlined /> Plants Powerflow
                        </div>
                        <ToolboxControl
                            displayFlowButton={true}
                            toggleFlow={this.toggleFlow}
                            flowDrag={this.state.flowDrag}
                            parentElementId={parentElementId}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height">
                    {/* { */}
                        {/* // chargeData ? */}
                            <FlowComponent
                                flowDrag={this.state.flowDrag}
                                toggleFlow={this.toggleFlow}
                            />
                            {/* :
                            <Loading />
                    } */}
                </div>
            </AntCard>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        findLocation: (coordinatesList, zoom) => {
            dispatch(findLocation(coordinatesList, zoom));
        },
        fetchTableEvent: (params) => {
            dispatch(fetchTableEvent(params));
        },
        getPlantDataOverview: params => {
            dispatch(getPlantDataOverview(params));
        }
    };
}

const mapStateToProps = state => {
    return {
        common: state.common,
        plant: state.plant,
        site: state.site,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomComponent);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { exitFullScreen, fullScreen } from "@features/Common/redux";
import {
    ReloadOutlined,
    SettingOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    PlusOutlined,
    PushpinOutlined,
    PushpinTwoTone
} from "@ant-design/icons";
import { AntButton } from "../../AntButton";
import { Select } from 'antd';

const { Option } = Select;

class CustomComponent extends Component {
    onClickFullScreen = () => {
        this.props.fullScreen(this.props.parentElementId)
        if (this.props.onChangeResize !== undefined) {
            this.props.onChangeResize(true)
        }
    }

    onExitClickFullScreen = () => {
        this.props.exitFullScreen()
        if (this.props.onChangeResize !== undefined) {
            this.props.onChangeResize(false)
        }
    }

    render() {
        const {
            common,
            updateAt,
            showSetting,
            showReload,
            handleReload,

            btnAddShow,
            btnAddText,
            btnAddClick,

            selectTimeOptions,
            selectTimeDefault,
            handleChangeTime,
            selectInverterOptions,
            handleChangeInverter,
            defaultDeviceCode,
            disabled,

            defaultTypeString,
            handleChangeTypeString,
            typeStringOptions,
            // Flow item
            flowDrag,
            toggleFlow,
            displayFlowButton
        } = this.props

        const isActive = common.fullScreen.isActive;
        return (
            <div className="card-toolbox">
                {
                    displayFlowButton &&
                    <span className="toolbox-control reload-control">
                        <AntButton
                            className="btn-main-default"
                            icon={flowDrag ? <PushpinTwoTone /> : <PushpinOutlined />}
                            onClick={toggleFlow}
                        />
                    </span>
                }
                {
                    showReload ?
                        <span className="toolbox-control reload-control">
                            <AntButton
                                className="btn-main-default"
                                icon={<ReloadOutlined />}
                                onClick={handleReload}
                            />
                        </span> : null
                }
                {
                    selectTimeOptions ? <span className="toolbox-control select-time-control">
                        <Select
                            defaultValue={selectTimeDefault !== undefined ? selectTimeDefault : "last_24_hour"}
                            listHeight={400}
                            style={{
                                width: "140px",
                            }}
                            disabled={disabled}
                            onChange={handleChangeTime}
                        >
                            {
                                selectTimeOptions.map((item, key) => {
                                    return <Option key={key} value={item.value}>{item.title}</Option>
                                })
                            }
                        </Select>
                    </span> : null
                }
                {
                    updateAt ?
                        <div className="toolbox-control datetime-control">
                            Last update: <b>{updateAt}</b>
                        </div> : null
                }

                {
                    btnAddShow ?
                        <span className="toolbox-control setting-control">
                            <AntButton
                                ghost
                                className="btn-success-ghost"
                                icon={<PlusOutlined />}
                                onClick={btnAddClick}
                            >
                                {btnAddText ?? "New"}
                            </AntButton>
                        </span> : null
                }

                <span> </span>
                {
                    showSetting ?
                        <span className="toolbox-control setting-control">
                            <AntButton
                                className="btn-main-default"
                                icon={<SettingOutlined />}
                            />
                        </span> : null
                }

                {
                    defaultDeviceCode &&
                    <span className="toolbox-control select-time-control">
                        <Select
                            listHeight={400}
                            style={{
                                width: "100% + 5px",
                            }}
                            disabled={disabled}
                            onChange={handleChangeInverter}
                            value={defaultDeviceCode}
                        >
                            {
                                selectInverterOptions.map(item => {
                                    return <Option key={item.code} value={item.code}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </span>
                }
                {
                    defaultTypeString &&
                    <span className="toolbox-control select-time-control">
                        <Select
                            listHeight={400}
                            style={{
                                width: "100% + 5px",
                            }}
                            disabled={disabled}
                            onChange={handleChangeTypeString}
                            value={defaultTypeString}
                        >
                            {
                                typeStringOptions.map(item => {
                                    return <Option key={item.value} value={item.value}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </span>
                }

                <span className="toolbox-control full-screen-control">
                    <AntButton
                        className="btn-main-default"
                        icon={isActive ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                        onClick={() => isActive ? this.onExitClickFullScreen() : this.onClickFullScreen()}
                    />
                </span>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fullScreen: (elementId) => {
            dispatch(fullScreen(elementId));
        },
        exitFullScreen: () => {
            dispatch(exitFullScreen());
        },
    };
}

function mapStateToProps(state) {
    return {
        common: state.common,
        plant: state.plant,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomComponent)
import React, { Component } from 'react';
import { Form, Input } from "antd";
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { AntButton, AntFormItem, } from "@layouts";
import { Link } from "react-router-dom";
import logo from "@images/logo.png";
import imageForm from "@images/formimage.jpg";

class President extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    onClick = () => {
        this.setState({
            ...this.state,
            loading: true,
        })
    }

    render() {
        const { errors } = this.props.crud;

        return (
            <div className="login-page">
                <div className="login-box">
                    <div className="illustration-wrapper">
                        <img
                            src={imageForm}
                            alt="Login"/>
                    </div>
                    <div>
                        <Form
                            name="login-form"
                            // onFinishFailed={this.onFinishFailed}
                            onFinish={this.props.handleLogin}
                        >
                            <div className={"login-title"}>
                                <img src={logo} alt="" className={"customLogo"}/>
                                <p className={'customInfo'}>Monitoring & control</p>
                            </div>
                            <AntFormItem
                                errors={errors.username}
                                name="email"
                            >
                                <Input
                                    prefix={<MailOutlined className="site-form-item-icon"/>}
                                    placeholder="Email"
                                />
                            </AntFormItem>

                            <AntFormItem
                                errors={errors.password}
                                name="password"
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon"/>}
                                    type="password"
                                    placeholder="Password"
                                />
                            </AntFormItem>

                            <AntButton
                                className="btn-main-primary"
                                type="primary"
                                htmlType="submit"
                                block={true}
                                loading={this.state.loading}
                                onClick={this.onClick}
                            >
                                Login
                            </AntButton>
                            <div className="form-option">
                                Forgot your password ? <Link to={'/password-request'}>Reset</Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }

    componentDidUpdate = prevProps => {
        const prevLoading = prevProps.loading;
        const currentLoading = this.props.loading;
        if (prevLoading !== currentLoading) {
            this.setState({
                ...this.state,
                loading: currentLoading,
            })
        }
    }
}

export default President;
import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { fetchPlants } from "../../redux";
import { resetStore } from "@features/Common/redux";
import { getSiteDetail } from "@features/Site/redux";
import { fetchTableEvent } from "@features/Event/redux";
import * as CONSTANTS from "@ultis/constants";
import { getPlantDataOverview } from "@features/Plant/redux";

class Container extends Component {
    onChangeResizeMap = () => {
        const { siteId } = this.props.match.params;
        this.props.fetchPlants(siteId);
    }

    render() {
        return (
            <President
                {...this.props}
                onChangeResizeMap={this.onChangeResizeMap}
                showReload={false}
            />
        )
    }

    componentDidMount() {
        this.fetchData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, () => this.fetchData())
    }

    componentDidUpdate(prevProps) {
        const preParams = prevProps.match.params;
        const currentParams = this.props.match.params;

        if ((preParams.siteId !== currentParams.siteId)) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        this.props.resetStore()
    }

    fetchData = () => {
        const { siteId, plantId } = this.props.match.params;
        const { plantOverview } = this.props.plant;
        const { data } = plantOverview;
        let paramsCall = {
            type: 'detail',
            siteId: siteId,
        }

        if (data.length) {
            let l = 0, r = data.length - 1, mid, res = [], pos = -1;
            while (l <= r) {
                mid = (r + l) >> 1;
                const findId = data[mid].site._id;
                if (findId >= siteId) {
                    pos = mid;
                    r = mid - 1;
                }
                else {
                    l = mid + 1;
                }
            }

            if (pos !== -1) {
                if (pos === data.length - 1) {
                    res.push(data[pos]);
                }
                else {
                    while (data[pos]?.site?._id === siteId) {
                        res.push(data[pos]);
                        ++pos;
                    }
                }
            }

            paramsCall = {
                type: 'findMatch',
                siteId: siteId,
                sitePayload: res,
            }
        }

        this.props.getPlantDataOverview(paramsCall);
        this.props.getSiteDetail(siteId);
        this.props.fetchPlants(siteId);
        this.props.fetchTableEvent({
            plantId: plantId,
            siteId: siteId,
        })
    }
}

function mapDispatchToProps(dispatch) {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },
        getSiteDetail: (siteId) => {
            dispatch(getSiteDetail(siteId));
        },
        fetchPlants: (siteId) => {
            dispatch(fetchPlants(siteId));
        },
        fetchTableEvent: (params) => {
            dispatch(fetchTableEvent(params));
        },
        getPlantDataOverview: params => {
            dispatch(getPlantDataOverview(params));
        },
    };
}


function mapStateToProps(state) {
    return {
        socketIO: state.socketIO,
        auth: state.auth,
        common: state.common,
        site: state.site,
        technicalMonitoring: state.technicalMonitoring,
        plant: state.plant,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container))
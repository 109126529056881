import React, { Component } from 'react';
import { ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import { FieldTimeOutlined, HddOutlined } from "@ant-design/icons";
import { AntCard, Loading } from "@layouts";
import { Col, Divider, Row, Table } from "antd";

class CustomComponent extends Component {
    render() {
        const { common, meta, detail, handleChangeInverter, defaultDeviceCode } = this.props

        const parentElementId = "plant-real-time-device";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

        // Init chart data
        // const { loading, options } = meta.stringMonitoring

        const { inverters } = detail.data;
        let inverterList = inverters ?? [];
        const { data, loading } = meta.inverterDetail;
        const { tableData, lastTime } = data;

        const formData = {
            PVampe1: 0,
            PVampe2: 0,
            PVampe3: 0,
            PVampe4: 0,
            PVampe5: 0,
            PVampe6: 0,
            PVampe7: 0,
            PVampe8: 0,
            PVampe9: 0,
            PVampe10: 0,
            PVampe11: 0,
            PVampe12: 0,
            PVampe13: 0,
            PVampe14: 0,
            PVampe15: 0,
            PVampe16: 0,
            PVampe17: 0,
            PVampe18: 0,
            PVampe19: 0,
            PVampe20: 0,
            PVvolt1: 0,
            PVvolt2: 0,
            PVvolt3: 0,
            PVvolt4: 0,
            PVvolt5: 0,
            PVvolt6: 0,
            PVvolt7: 0,
            PVvolt8: 0,
            PVvolt9: 0,
            PVvolt10: 0,
            PVvolt11: 0,
            PVvolt12: 0,
            PVvolt13: 0,
            PVvolt14: 0,
            PVvolt15: 0,
            PVvolt16: 0,
            PVvolt17: 0,
            PVvolt18: 0,
            PVvolt19: 0,
            PVvolt20: 0,
            totalSystemVolt: 0,
            totalSystemCurrent: 0,
            systemSOC: 0,
            systemSOH: 0,
            systemInsulationValue: 0,
            systemDischargeCapacity: 0,
            maximumAllowCharge: 0,
            maximumAllowDischarge: 0,
            currentDif: 0,
            totalVoltDifCluster: 0,
            maximumBatteryVolt: 1000,
            minimumBatteryVolt: 100,
            avgSystemVolt: 0,
            maximumBatteryTemp: 80,
            minimumBatteryTemp: 10,
            avgBatteryTemp: 0,
            accumulateChargeQuantity: 0,
            accumulateDischargeQuantity: 0,
            maximumAllowChargePower: 0,
            maximumAllowDischargePower: 0
        }

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <FieldTimeOutlined /> RealTime Device Data
                        </div>
                        <span className="toolbox-control select-time-control">
                            <ToolboxControl
                                updateAt={lastTime}
                                disabled={!inverterList.length}
                                parentElementId={parentElementId}
                                selectInverterOptions={inverterList}
                                handleChangeInverter={handleChangeInverter}
                                defaultDeviceCode={defaultDeviceCode}
                            />
                        </span>
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height">
                    {loading ? <Loading overwrite /> : null}
                    <Table
                        columns={tableData && tableData.length > 0 ? renderColumn() : []}
                        dataSource={tableData ?? []}
                        pagination={false}
                        loading={false}
                        rowKey={"string"}
                        scroll={{ x: 1000 }}
                    >
                    </Table>
                    <Divider orientation="left"></Divider>
                    <div className={"form-realtime-device-data"}>
                        {
                            formData &&
                            <>
                                <h3><HddOutlined /> Inverter Realtime Data Detail</h3>
                                {
                                    renderForm(formData)
                                }
                            </>
                        }
                    </div>
                </div>
            </AntCard>
        )
    }
}

const paramList = formData => {
    return [
        [
            { title: 'Inverter Status', value: formData.inverterStatus },
            { title: 'Active power', value: formData.activePower },
            { title: 'Power factor', value: formData.powerFactor },
            { title: 'Grid phase A current', value: formData.gridPhaseACurrent },
            { title: 'Grid AB line voltage', value: formData.gridABLineVoltage },
            { title: 'Inverter startup time', value: formData.inverterStartupTime },
            { title: 'Insulation resistance', value: formData.insulationResistance },
        ],
        [
            { title: 'Daily energy', value: formData.dailyEnergy },
            { title: 'Output reactive power', value: formData.outputReactivePower },
            { title: 'Grid frequency', value: formData.gridFrequency },
            { title: 'Grid phase B current', value: formData.gridPhaseBCurrent },
            { title: 'Grid BC line Voltage', value: formData.gridBCLineVoltage },
            { title: 'Inverter shutdown time', value: formData.inverterShutdownTime },
        ],
        [
            { title: 'Cumulative energy', value: formData.cumulativeEnergy },
            { title: 'Inverter rated power', value: formData.inverterRatedPower },
            { title: 'Output mode', value: formData.outputMode },
            { title: 'Grid phase C current', value: formData.gridPhaseCCurrent },
            { title: 'Grid CA line voltage', value: formData.gridCALineVoltage },
            { title: 'Internal temperature', value: formData.internalTemperature },
        ],
    ]
}

const renderForm = formData => {
    const data = paramList(formData);
    return (
        <Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40, }}>
            {
                data.map((element, index) =>
                    <Col className="gutter-row" span={8} key={index}>
                        {
                            element.map(item => <div key={item.title}>{item.title} <b>{item.value}</b></div>)
                        }
                    </Col>
                )
            }
        </Row>
    )
}

function mapStateToProps(state) {
    return {
        common: state.common,
        plant: state.plant,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);

const renderColumn = () => {
    let column = [
        {
            title: 'String',
            dataIndex: 'string',
            key: 'string',
            width: 150,
            ellipsis: true,
        },
    ]
    for (let i = 1; i <= 20; ++i) {
        column.push({
            title: `PV${i}`,
            dataIndex: `PV${i}`,
            key: `PV${i}`,
            width: 80,
            ellipsis: true,
        })
    }
    return column;
}
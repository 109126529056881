import initialState from "./initialState";
import * as CONSTANTS from "./constants";
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";

export function reducer(state = initialState, action) {
    let payload = action.payload;
    switch (action.type) {
        /**
         * Reset action
         */
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        /**
         * Site logic
         */
        case CONSTANTS.FETCH_SITES_OVERVIEW_LOADING_ACTION:
            return {
                ...state,
                overview: {
                    ...state.overview,
                    loading: true,
                }
            };
        case CONSTANTS.FETCH_SITES_OVERVIEW_ACTION:
            // Init plant on map
            let coordinatesList = [];
            payload = payload ?? {}
            let plants = payload.plants ?? []
            const chargeData = {
                gauges: payload.gauges ?? {},
                batteryPower: payload.batteryPower ?? "0 kW",
                gridPower: payload.gridPower ?? "0 W",
                housePower: payload.housePower ?? "0 kW",
                solarPower: payload.solarPower ?? "0 kW",
                latestData: payload.latestData ?? {},
                powerValue: payload.powerValue ?? 0,
                production: payload.production ?? {},
                series: payload.series ?? [],
            }

            // Case load map with default list (When refresh data page)
            if (state.overview.coordinatesSelected.length > 0) {
                state.overview.coordinatesSelected.forEach(item => {
                    coordinatesList.push(item)
                })
            } else {
                plants.forEach(item => {
                    coordinatesList.push(item)
                })
            }

            return {
                ...state,
                overview: {
                    ...state.overview,
                    loading: false,
                    total: payload.total ?? 0,
                    powerValue: payload.powerValue ?? 0,
                    powerUnit: payload.powerUnit ?? '',
                    plants: plants,
                    coordinatesList: coordinatesList,
                    chargeData: chargeData,
                }
            };
        case CONSTANTS.FIND_LOCATION_ACTION:
            return {
                ...state,
                overview: {
                    ...state.overview,
                    coordinatesList: payload.coordinatesList,
                    zoom: payload.zoom,
                    coordinatesSelected: payload.coordinatesList,
                }
            };

        default:
            return state;
    }
}
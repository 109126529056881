import React, { Component } from 'react';
import { ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import { FieldTimeOutlined, HddOutlined } from "@ant-design/icons";
import { AntCard, Loading } from "@layouts";
import { Col, Divider, Row, Table } from "antd";

class CustomComponent extends Component {
    render() {
        const { common, overview: { loading } } = this.props

        // const latestData = chargeData?.latestData ?? {};

        const latestData = {
            "totalSystemVolt": 0,
            "totalSystemCurrent": 0,
            "systemSOC": 0,
            "systemSOH": 0,
            "systemInsulationValue": 0,
            "systemDischargeCapacity": 0,
            "maximumAllowCharge": 0,
            "maximumAllowDischarge": 0,
            "currentDif": 0,
            "totalVoltDifCluster": 0,
            "maximumBatteryVolt": 1000,
            "minimumBatteryVolt": 100,
            "avgSystemVolt": 0,
            "maximumBatteryTemp": 80,
            "minimumBatteryTemp": 10,
            "avgBatteryTemp": 0,
            "accumulateChargeQuantity": 0,
            "accumulateDischargeQuantity": 0,
            "maximumAllowChargePower": 0,
            "maximumAllowDischargePower": 0
        }

        const parentElementId = "plant-real-time-device";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

        let batteryList = [], value,
            params = [
                { string: "Voltage (V)" },
                { string: "Current (A)" },
            ];

        for (let i = 1; i <= 20; ++i) {
            value = latestData[`PVvolt${i}`] ?? 0;
            params[0][`PV${i}`] = value;

            value = latestData[`PVampe${i}`] ?? 0;
            params[1][`PV${i}`] = value;
        }

        for (let i = 1; i <= 10; ++i) {
            batteryList.push({
                id: `BATTERY${i}`,
                code: `BATTERY${i}`,
                name: `Battery ${i}`
            })
        }

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <FieldTimeOutlined /> RealTime Charge Data
                        </div>
                        <span className="toolbox-control select-time-control">
                            <ToolboxControl
                                parentElementId={parentElementId}
                                selectInverterOptions={batteryList}
                                handleChangeInverter={event => console.log(event)}
                                defaultDeviceCode={'BATTERY1'}
                            />
                        </span>
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height">
                    {/* {loading ? <Loading overwrite /> : null} */}
                    {/* <Table
                        columns={renderColumn()}
                        dataSource={params}
                        pagination={false}
                        loading={false}
                        rowKey={"string"}
                        scroll={{ x: 1000 }}
                    /> */}
                    {/* <Divider orientation="left"></Divider> */}
                    <div className={"form-realtime-device-data"}>
                        {
                            latestData &&
                            <>
                                <h3><HddOutlined /> Battery Realtime Data Detail</h3>
                                {
                                    renderForm(latestData)
                                }
                            </>
                        }
                    </div>
                </div>
            </AntCard>
        )
    }
}

const paramList = formData => {
    return [
        [
            { title: 'Total System Voltage', value: `${formData.totalSystemVolt} V` },
            { title: 'Total System Current', value: `${formData.totalSystemCurrent} A` },
            { title: 'System SOC', value: `${formData.systemSOC} %` },
            { title: 'System SOH', value: `${formData.systemSOH} %` },
            { title: 'System insulation value', value: `${formData.systemInsulationValue} kOhm` },
            { title: 'System discharge capacity', value: `${formData.systemDischargeCapacity} kWh` },
            { title: 'Maximum allowable charging', value: `${formData.maximumAllowCharge} A` },
            { title: 'Maximum allowable discharge', value: `${formData.maximumAllowDischarge} A` },
            { title: 'Current different between cluster', value: `${formData.currentDif} A` },
            { title: 'Total voltage difference between cluster', value: `${formData.totalVoltDifCluster} V` },
        ],
        [
            { title: 'Maximum battery voltage of system', value: `${formData.maximumBatteryVolt} V` },
            { title: 'Minimum battery voltage of system', value: `${formData.minimumBatteryTemp} V` },
            { title: 'Average system voltage', value: `${formData.avgSystemVolt} V` },
            { title: 'Maximum battery temperature of system', value: `${formData.maximumBatteryTemp} °C` },
            { title: 'Minimum battery temperature', value: `${formData.minimumBatteryTemp} °C` },
            { title: 'Average system temperature', value: `${formData.avgBatteryTemp} °C` },
            { title: 'Accumulated charging quantity', value: `${formData.accumulateChargeQuantity} kWh` },
            { title: 'Accumulated discharge quantity', value: `${formData.accumulateDischargeQuantity} kWh` },
            { title: 'Maximum allowable discharge power', value: `${formData.maximumAllowDischargePower} kW` },
            { title: 'Minimum allowable charging power', value: `${formData.maximumAllowChargePower} kW` },
        ],
    ]
}

const renderForm = formData => {
    const data = paramList(formData);
    return (
        <Row gutter={{ xs: 24, sm: 32, md: 48, lg: 40, }}>
            {
                data.map((element, index) =>
                    <Col className="gutter-row" span={10} key={index}>
                        {
                            element.map(item => <div key={item.title}>{item.title} <b>{item.value}</b></div>)
                        }
                    </Col>
                )
            }
        </Row>
    )
}

function mapStateToProps(state) {
    return {
        common: state.common,
        plant: state.plant,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);

const renderColumn = () => {
    let column = [
        {
            title: 'Module',
            dataIndex: 'string',
            key: 'string',
            width: 150,
            ellipsis: true,
        },
    ]
    for (let i = 1; i <= 20; ++i) {
        column.push({
            title: `Cell ${i}`,
            dataIndex: `PV${i}`,
            key: `PV${i}`,
            width: 80,
            ellipsis: true,
        })
    }
    return column;
}
import "./style.scss"
import Flow from './Flow'

const FlowComponent = ({ flowDrag, toggleFlow }) => {
    return (
        <div id="app">
            <Flow
                flowDrag={flowDrag}
                toggleFlow={toggleFlow}
            />
        </div>
    )
}

export default FlowComponent;
import React from 'react';
import { BaseEdge, getSmoothStepPath } from '@xyflow/react';

export function AnimatedSVGEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
}) {
    const [edgePath] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    let duration = '1.5s', fill = "#ff0073"
    if (id === '1->2') {
        fill = "#ffa940"
    }
    if (id === '2->3') { // Done
        fill = "red"
    }
    if (id === '3->4') { // Done
        fill = "#00C000"
    }
    if (id === '11->33') { // Done
        fill = "#ffa940"
    }
    if (id === '22->44') {
        fill = "red"
    }
    if (id === '111->444') {
        fill = "#ffa940"
    }

    return (
        <>
            <BaseEdge id={id} path={edgePath} />
            <circle r="10" fill={fill}>
                <animateMotion dur={duration} repeatCount="indefinite" path={edgePath} />
            </circle>
            {
                (id === '3->4' || id === '2->3') &&
                <>
                    <CircleComponent
                        fill={fill}
                        duration={duration}
                        edgePath={edgePath}
                        begin={`${parseFloat(duration) / 20}s`}
                    />
                    <CircleComponent
                        fill={fill}
                        duration={duration}
                        edgePath={edgePath}
                        begin={`${parseFloat(duration) / 10}s`}
                    />
                </>
            }
            {
                id === '11->33' &&
                <>
                    <CircleComponent
                        fill={fill}
                        duration={duration}
                        edgePath={edgePath}
                        begin={`${parseFloat(duration) / 14}s`}
                    />
                    <CircleComponent
                        fill={fill}
                        duration={duration}
                        edgePath={edgePath}
                        begin={`${parseFloat(duration) / 7}s`}
                    />
                </>
            }
            {
                (id === '1->2' || id === '111->444') &&
                <>
                    <CircleComponent
                        fill={fill}
                        duration={duration}
                        edgePath={edgePath}
                        begin={`${parseFloat(duration) / 22}s`}
                    />
                    <CircleComponent
                        fill={fill}
                        duration={duration}
                        edgePath={edgePath}
                        begin={`${parseFloat(duration) / 11}s`}
                    />
                </>
            }
            {
                id === '22->44' &&
                <>
                    <CircleComponent
                        fill={fill}
                        duration={duration}
                        edgePath={edgePath}
                        begin={`${parseFloat(duration) / 28}s`}
                    />
                    <CircleComponent
                        fill={fill}
                        duration={duration}
                        edgePath={edgePath}
                        begin={`${parseFloat(duration) / 14}s`}
                    />
                </>
            }
        </>
    );
}

const CircleComponent = ({ fill, duration, edgePath, begin }) => {
    return (
        <circle r="7" fill={fill}>
            <animateMotion
                dur={duration}
                repeatCount="indefinite"
                path={edgePath}
                begin={begin}
            />
        </circle>
    )
}
import React, { Component } from 'react';
import {
    AntButton,
    AntInput, ModalConfirm,
    ToolboxControl
} from "@layouts";
import { connect } from "react-redux";
import { AntCard, Loading } from "@layouts";
import {
    InfoCircleOutlined,
    CloudDownloadOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Checkbox, Radio, Select, Space, Switch, DatePicker, TimePicker, Row, Col, Alert } from "antd";
import { reportUpdate } from "@features/Tools/Report/redux";
import * as CONSTANTS from "@ultis/constants";

class CustomComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            automatic: {
                activeAutomatic: false,
                fileType: [],
                patternType: null,
                patternValue: {},
            },

            automaticModalVisible: false
        }
    }

    onChangeActive = (value) => {
        this.setState({
            ...this.state,
            automatic: {
                ...this.state.automatic,
                activeAutomatic: value
            }
        })
    }

    onChangeFileType = (value) => {
        this.setState({
            ...this.state,
            automatic: {
                ...this.state.automatic,
                fileType: value
            }
        })
    }

    onChangePatternType = (e) => {
        let patternParse = parseAutomaticConfigs(e.target.value, {})
        this.setState({
            ...this.state,
            automatic: {
                ...this.state.automatic,
                patternType: patternParse.patternType,
                patternValue: patternParse.patternValue,
            }
        })
    }

    onChangePatternDaily = (value) => {
        this.setState({
            ...this.state,
            automatic: {
                ...this.state.automatic,
                patternValue: {
                    startAt: value
                }
            }
        })
    }

    onChangePatternWeekly = (value) => {
        this.setState({
            ...this.state,
            automatic: {
                ...this.state.automatic,
                patternValue: {
                    days: value
                }
            }
        })
    }

    onChangePatternMonthly = (e) => {
        this.setState({
            ...this.state,
            automatic: {
                ...this.state.automatic,
                patternValue: {
                    date: e.target.value
                }
            }
        })
    }

    onChangePatternYearly = (value) => {
        this.setState({
            ...this.state,
            automatic: {
                ...this.state.automatic,
                patternValue: {
                    startAt: value
                }
            }
        })
    }

    onConfirmAutomaticModalConfirm = () => {
        let automatic = this.state.automatic;
        let patternValue = parseAutomaticRequest(automatic.patternType, automatic.patternValue);
        this.props.reportUpdate(this.props.report.detail.data.id, {
            ...automatic,
            patternValue: patternValue
        });
        this.onHideAutomaticModalConfirm();
    }

    onShowAutomaticModalConfirm = () => {
        this.setState({
            ...this.state,
            automaticModalVisible: true
        })
    }

    onHideAutomaticModalConfirm = () => {
        this.setState({
            ...this.state,
            automaticModalVisible: false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const preDetail = prevProps.report.detail.data;
        const currentDetail = this.props.report.detail.data;

        if (preDetail.id !== currentDetail.id) {
            let automatic = currentDetail.automatic ?? {};
            let pattern = automatic.pattern ?? {}
            let patternType = pattern.type ?? CONSTANTS.PATTERN_TYPE.DAILY;
            let patternValue = pattern.value ?? {};
            let patternParse = parseAutomaticConfigs(patternType, patternValue)

            this.setState({
                ...this.state,
                automatic: {
                    activeAutomatic: automatic.activeAutomatic ?? false,
                    fileType: automatic.fileType ?? [],
                    patternType: patternParse.patternType,
                    patternValue: patternParse.patternValue,
                }
            })
        }
    }

    render() {
        const { common } = this.props
        const parentElementId = "analysis-detail";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

        let {
            reportType,
            downloadRequest,
            onClickDownload,
            onChangeTimePeriod,
            onChangeFileType,
            onChangeTimeRanges,
            report
        } = this.props

        // Instant Export
        let downloadLoading = report.downloadRequest.loading
        const { automatic, automaticModalVisible } = this.state

        // Automatic Export Detail
        let detailData = report.detail.data;
        let detailLoading = report.detail.loading;
        let isLoaded = !!detailData.id

        // Automatic Export Update
        let updateLoading = report.update.loading;

        const { fileType, timePeriod, timeRanges } = downloadRequest

        // Render list time
        let suggestRanges = {}
        let picker = ''
        let listPeriod = []
        switch (reportType) {
            case CONSTANTS.REPORT_TYPE.PLANT_REPORT:
                suggestRanges = timePeriod === CONSTANTS.TIME_PERIOD.MONTHLY ? plantMonthRanges : plantYearRanges;
                picker = timePeriod === CONSTANTS.TIME_PERIOD.MONTHLY ? "week" : "month";
                listPeriod = plantListPeriod
                break
            case CONSTANTS.REPORT_TYPE.DEVICE_REPORT:
                suggestRanges = deviceWeekRanges;
                picker = timePeriod === CONSTANTS.TIME_PERIOD.MONTHLY ? "" : "week";
                listPeriod = deviceListPeriod
                break
            default:
                break
        }

        return (
            <AntCard
                id={parentElementId}
                className={"report-detail card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <InfoCircleOutlined/> Report details
                        </div>
                        <ToolboxControl parentElementId={parentElementId}/>
                    </div>
                }
            >
                <div className="card-custom-body">

                    {
                        isLoaded && detailLoading ? <Loading overwrite={true}/> : null
                    }
                    {
                        !isLoaded ?
                            <div>
                                <Alert message="Please choose a report." type="warning"/>
                            </div> :
                            <div>
                                <div className="report-section">
                                    <div className="section-title">Instant Export</div>
                                    <div className="section-item">
                                        <div className="label">
                                            Time period:
                                        </div>
                                        <div className="value">
                                            <Select
                                                value={timePeriod}
                                                style={{ width: 300 }}
                                                onChange={onChangeTimePeriod}
                                            >
                                                {
                                                    listPeriod.map((item, key) => {
                                                        return <Select.Option key={key}
                                                                              value={item.value}>{item.title}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="section-item">
                                        <div className="label">
                                            Select times:
                                        </div>
                                        <div className="value">
                                            <DatePicker.RangePicker
                                                style={{ width: 300 }}
                                                value={timeRanges}
                                                ranges={suggestRanges}
                                                format={CONSTANTS.DEFAULT_FORMAT_DATE}
                                                picker={picker}
                                                onChange={onChangeTimeRanges}
                                            />
                                        </div>
                                    </div>
                                    <div className="section-item">
                                        <div className="label">
                                            File type:
                                        </div>
                                        <div className="value">
                                            <Select
                                                defaultValue={fileType}
                                                onChange={onChangeFileType}
                                                style={{ width: 300 }}
                                            >
                                                {
                                                    listType.map((item, key) => {
                                                        return <Select.Option
                                                            key={key}
                                                            value={item.value}>{item.title}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="section-button">
                                        <AntButton
                                            //type="primary"
                                            className="btn-primary"
                                            icon={<CloudDownloadOutlined/>}
                                            onClick={onClickDownload}
                                            loading={downloadLoading}
                                        >
                                            DownLoad
                                        </AntButton>
                                    </div>
                                </div>
                                <div className="report-section">
                                    <div className="section-title">Automatic Export</div>
                                    <div className="section-item">
                                        <div className="label">
                                            Active:
                                        </div>
                                        <div className="value">
                                            <Switch
                                                checked={automatic.activeAutomatic}
                                                onChange={this.onChangeActive}
                                            />
                                        </div>
                                    </div>
                                    <div className="section-item">
                                        <div className="label">
                                            File type:
                                        </div>
                                        <div className="value">
                                            <Select
                                                mode="multiple"
                                                value={automatic.fileType}
                                                onChange={this.onChangeFileType}
                                                listHeight={400}
                                                style={{
                                                    width: 300,
                                                }}
                                            >
                                                {
                                                    listType.map((item, key) => {
                                                        return <Select.Option key={key}
                                                                              value={item.value}>{item.title}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="section-item">
                                        <div className="label">
                                            Pattern
                                        </div>
                                        <div className="value">
                                            <div className="group-pattern">
                                                <div className="group-pattern-item">
                                                    <Radio.Group
                                                        onChange={this.onChangePatternType}
                                                        value={automatic.patternType}
                                                    >
                                                        <Space direction="vertical">
                                                            <Radio value={CONSTANTS.PATTERN_TYPE.DAILY}>
                                                                Daily
                                                            </Radio>
                                                            <Radio value={CONSTANTS.PATTERN_TYPE.WEEKLY}>
                                                                Weekly
                                                            </Radio>
                                                            <Radio value={CONSTANTS.PATTERN_TYPE.MONTHLY}>
                                                                Monthly
                                                            </Radio>
                                                            <Radio value={CONSTANTS.PATTERN_TYPE.YEARLY}>
                                                                Yearly
                                                            </Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                </div>
                                                <div className="group-pattern-item">
                                                    {
                                                        automatic.patternType === CONSTANTS.PATTERN_TYPE.DAILY ?
                                                            <PatternDaily
                                                                onChange={this.onChangePatternDaily}
                                                                value={automatic.patternValue}
                                                            /> : null
                                                    }
                                                    {
                                                        automatic.patternType === CONSTANTS.PATTERN_TYPE.WEEKLY ?
                                                            <PatternWeekly
                                                                onChange={this.onChangePatternWeekly}
                                                                value={automatic.patternValue}
                                                            /> : null
                                                    }
                                                    {
                                                        automatic.patternType === CONSTANTS.PATTERN_TYPE.MONTHLY ?
                                                            <PatternMonthly
                                                                onChange={this.onChangePatternMonthly}
                                                                value={automatic.patternValue}
                                                            /> : null
                                                    }
                                                    {
                                                        automatic.patternType === CONSTANTS.PATTERN_TYPE.YEARLY ?
                                                            <PatternYearly
                                                                onChange={this.onChangePatternYearly}
                                                                value={automatic.patternValue}
                                                            /> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-button">
                                        <AntButton
                                            //type="primary"
                                            className="btn-success"
                                            icon={<SaveOutlined/>}
                                            loading={updateLoading}
                                            onClick={this.onShowAutomaticModalConfirm}
                                        >
                                            Save
                                        </AntButton>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                <ModalConfirm
                    title="Update Automatic Export"
                    onOk={this.onConfirmAutomaticModalConfirm}
                    onCancel={this.onHideAutomaticModalConfirm}
                    visible={automaticModalVisible}
                    message={"Do you want update ?"}
                />
            </AntCard>
        )
    }
}

/**
 * Parse automatic request
 * @param patternType
 * @param patternValue
 * @returns {{startAt: (*|null)}|{}}
 */
function parseAutomaticRequest(patternType, patternValue) {
    patternValue = patternValue ?? {}
    switch (patternType) {
        case CONSTANTS.PATTERN_TYPE.DAILY:
            patternValue = {
                startAt: patternValue.startAt ? patternValue.startAt.format(CONSTANTS.DEFAULT_FORMAT_TIME) : null
            }
            break;
        case CONSTANTS.PATTERN_TYPE.YEARLY:
            patternValue = {
                startAt: patternValue.startAt ? patternValue.startAt.format(CONSTANTS.DEFAULT_FORMAT_DATETIME) : null
            }
            break;
        default:
            break;

    }
    return patternValue;
}

function parseAutomaticConfigs(patternType, patternValue) {
    patternValue = patternValue ?? {}
    switch (patternType) {
        case CONSTANTS.PATTERN_TYPE.DAILY:
            patternValue = {
                startAt: patternValue.startAt ? moment(patternValue.startAt, CONSTANTS.DEFAULT_FORMAT_TIME) : null
            }
            break;

        case CONSTANTS.PATTERN_TYPE.WEEKLY:
            patternValue = {
                days: patternValue.days ?? []
            }
            break;
        case CONSTANTS.PATTERN_TYPE.MONTHLY:
            patternValue = {
                date: patternValue.date ?? null
            }
            break;
        case CONSTANTS.PATTERN_TYPE.YEARLY:
            patternValue = {
                startAt: patternValue.startAt ? moment(patternValue.startAt, CONSTANTS.DEFAULT_FORMAT_DATETIME) : moment()
            }
            break;
        default:
            break;

    }
    return {
        patternType: patternType,
        patternValue: patternValue,
    }
}

const PatternDaily = (props) => {
    const { onChange, value } = props
    let dailyValue = value.startAt ?? null;
    return (
        <div className="pattern-daily">
            Start At: <TimePicker onChange={onChange} value={dailyValue}/>
        </div>
    )
}

const PatternWeekly = (props) => {
    const { onChange, value } = props
    let days = value.days ?? []
    return (
        <div className="pattern-weekly">
            <Checkbox.Group
                value={days}
                onChange={onChange}
            >
                <Row>
                    {
                        DaysOfWeek.map((item, idx) => {
                            return (
                                <Col span={8} key={idx}>
                                    <Checkbox
                                        value={item.value}
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                    >
                                        {item.label}
                                    </Checkbox>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Checkbox.Group>
        </div>
    )
}

const PatternMonthly = (props) => {
    const { onChange, value } = props
    let monthlyValue = value.date;
    let select = monthlyValue === 'first' || monthlyValue === 'last' ? monthlyValue : 'others';
    let otherValue = select === 'others' ? monthlyValue : null;

    return (
        <div className="pattern-monthly">
            <Radio.Group
                onChange={onChange}
                value={select}
            >
                <Space direction="vertical">
                    <Radio
                        value="first"
                    >
                        Start of month
                    </Radio>
                    <Radio
                        value="last"
                    >
                        End of month
                    </Radio>
                    <Space>
                        <Radio
                            value="others"
                        >
                            Other day:
                        </Radio>
                        <AntInput
                            value={otherValue}
                        />
                    </Space>
                </Space>
            </Radio.Group>
        </div>
    )
}

const PatternYearly = (props) => {
    const { onChange, value } = props
    let yearlyValue = value.startAt ?? null;
    return (
        <div className="pattern-yearly">
            Start on: <DatePicker
            showTime
            format={CONSTANTS.YEARLY_FORMAT_TIME}
            onChange={onChange}
            value={yearlyValue}
        />
        </div>
    )
}

const DaysOfWeek = [
    {
        "label": "Monday",
        "value": "monday"
    },
    {
        "label": "Tuesday",
        "value": "tuesday"
    },
    {
        "label": "Wednesday",
        "value": "wednesday"
    },
    {
        "label": "Thursday",
        "value": "thursday"
    },
    {
        "label": "Friday",
        "value": "friday"
    },
    {
        "label": "Saturday",
        "value": "saturday"
    },
    {
        "label": "Sunday",
        "value": "sunday"
    }
]

const plantListPeriod = [
    { title: "Month", value: CONSTANTS.TIME_PERIOD.MONTHLY },
    { title: "Year", value: CONSTANTS.TIME_PERIOD.YEARLY },
]

const deviceListPeriod = [
    { title: "Week", value: CONSTANTS.TIME_PERIOD.WEEK },
]

/**
 * Suggest month ranges
 * @type {}
 */
const plantMonthRanges = {
    'Last 30 days': [
        moment().subtract(30, 'days').startOf('date'),
        moment().endOf('date')
    ],
    'This Month': [
        moment().startOf('month'),
        moment().endOf('month')
    ],
}

/**
 * Suggest year ranges
 * @type {}
 */
const plantYearRanges = {
    'Last 6 months': [
        moment().subtract(6, "months").startOf('month'),
        moment().endOf('month')
    ],
    'Last 12 months': [
        moment().subtract(12, "months").startOf('month'),
        moment().endOf('month')
    ],
    'This year': [moment().startOf("year"), moment().endOf('month')],
}

/**
 * Suggest month ranges
 * @type {}
 */
const deviceWeekRanges = {
    'Last 7 days': [
        moment().subtract(7, 'days').startOf('date'),
        moment().endOf('date')
    ],
    'This Week': [
        moment().startOf('week'),
        moment().endOf('week')
    ],
}

const listType = [
    { title: "PDF", value: CONSTANTS.REPORT_FILE_TYPE.PDF },
    { title: "Excel", value: CONSTANTS.REPORT_FILE_TYPE.EXCEL },
]

function mapStateToProps(state) {
    return {
        common: state.common,
        plant: state.plant,
        report: state.report,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        reportUpdate: (reportId, params) => {
            dispatch(reportUpdate(reportId, params));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomComponent)

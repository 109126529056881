import React, { Component } from 'react';
import { Col, Row, Skeleton } from "antd";
import * as PlantComponents from "../../components";

class President extends Component {

    render() {
        const {
            handleChangeTimeActual,
            onChangeResizeChart,
            handleChangeInverter,
            defaultDeviceCode,
            handleChangeTypeString,
            defaultTypeString,
            technicalMonitoring,
            common,
            auth
        } = this.props

        const { overview } = technicalMonitoring

        const {
            detail,
            meta
        } = this.props.plant

        const { user: { id } } = auth;

        const {
            data,
            loading,
        } = detail

        const isBiwase = id === "64756d8fb15c1e148041ad6a";

        const isAbleToShow = detail.data.id === '63ec9b0820389cfa49a273fd';

        return (
            <div className="features feature-plant">
                <h1 className="page-title">
                    {loading ? <Skeleton.Input active={loading} /> : data.name}
                </h1>

                <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                    <Col xs={24} xl={15}>
                        {
                            isAbleToShow ?
                                <PlantComponents.FlowCard />
                                :
                                <PlantComponents.PlantActualProductionAndIrradiation
                                    handleChangeTime={handleChangeTimeActual}
                                    onChangeResizeChart={onChangeResizeChart}
                                    meta={meta}
                                />
                        }
                    </Col>
                    <Col xs={24} xl={9}>
                        {
                            isAbleToShow ?
                                <PlantComponents.SiteGauges />
                                :
                                <PlantComponents.PlantGauges
                                    meta={meta}
                                />
                        }
                    </Col>
                </Row>

                {
                    isAbleToShow &&
                    <div>
                        <PlantComponents.ChargingCurve />
                    </div>
                }

                {
                    (!isAbleToShow || isBiwase) &&
                    <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                        <Col xs={24}>
                            <PlantComponents.PlantRealTimeDevice
                                defaultDeviceCode={defaultDeviceCode}
                                handleChangeInverter={handleChangeInverter}
                                detail={detail}
                                meta={meta}
                            />
                        </Col>
                    </Row>
                }

                {
                    isAbleToShow &&
                    <div>
                        <PlantComponents.CellTable overview={overview} />
                    </div>
                }
        
                <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                    <Col xs={24} xl={12}>
                        <PlantComponents.PlantStringMonitoring
                            handleChangeTypeString={handleChangeTypeString}
                            defaultTypeString={defaultTypeString}
                            meta={meta}
                        />
                    </Col>
                    <Col xs={24} xl={12}>
                        <PlantComponents.PlantIVCurve
                            meta={meta}
                        />
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                    <Col xs={24}>
                        <PlantComponents.PlantInverterMonitoring
                            meta={meta}
                        />
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                    <Col xs={24}>
                        <PlantComponents.EventList />
                    </Col>
                </Row>
            </div>

        )
    }
}

export default President;